/* stylelint-disable scss/dollar-variable-empty-line-before */
/* stylelint-disable-next-line value-keyword-case */
/** Bulma: Variables **/
/** Main **/
#b {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  display: inline-block;
  margin-bottom: 40px;
  vertical-align: top;
}

.logo {
  max-height: 200px;
}
.logo.is-2x {
  display: none;
}

#dropzone * {
  pointer-events: none;
}

#panel {
  display: none;
}

#maxSize {
  font-size: 1rem;
}

.dz-preview .dz-details {
  display: flex;
}
.dz-preview .dz-details .dz-size,
.dz-preview .dz-details .dz-filename {
  flex: 1;
}
.dz-preview img,
.dz-preview .dz-success-mark,
.dz-preview .dz-error-mark {
  display: none;
}

.uploads {
  display: flex;
  flex-direction: column;
}
.uploads.is-reversed {
  flex-direction: column-reverse;
}
.uploads > div {
  margin: 0.75rem !important;
}
.uploads > div:first-child {
  margin-top: 1.5rem;
}
.uploads > div > .icon:not(.icon-block) {
  color: hsl(208deg, 79%, 51%);
}
.uploads > div > .icon.icon-block {
  color: hsl(348deg, 100%, 61%);
}
.uploads.is-reversed > div {
  flex: 0 0 auto;
}
.uploads.nojs {
  margin-bottom: 0;
}
.uploads .descriptive-progress {
  color: hsl(0deg, 0%, 93%);
}
.uploads img {
  max-width: 200px;
}

.name {
  font-size: 1rem;
  color: hsl(0deg, 0%, 96%);
  word-break: break-all;
}

.link > a {
  word-break: break-all;
}

.clipboard-mobile {
  margin-top: 5px;
}

#albumDiv .control {
  text-align: inherit;
}

#linksColumn {
  margin-top: -0.25rem;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
#linksColumn .column {
  padding: 0.25rem;
}
#linksColumn > span {
  padding: 0 0.3rem;
  color: hsl(0deg, 0%, 71%);
}

.git-commit a {
  display: inline-block;
  word-break: break-all;
}

.hero .tabs ul {
  border-bottom: 1px solid hsl(0deg, 0%, 48%);
}

#tabs {
  margin-bottom: 1rem;
}
#tabs .tab-content {
  margin-bottom: -0.75rem;
}

#tab-config.tab-content form {
  margin-bottom: 0.75rem;
}

#urlMaxSize {
  font-weight: bold;
}

input[type=file].is-fullwidth {
  width: 100%;
}

/** Render **/
.render {
  position: fixed;
  right: 0;
  bottom: 0;
  font-size: 1rem;
  color: hsl(0deg, 0%, 93%);
  cursor: pointer;
  animation: float-up 1.5s cubic-bezier(0, 0.71, 0.29, 1) 0.5s 1 normal both;
}
.render.button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  right: 1%;
  opacity: 0.25;
  transition: opacity 0.25s;
}
.render.button:hover {
  opacity: 1;
}

/** Newsfeed **/
#newsfeed {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 1.5rem 1.5rem 0;
}
#newsfeed .column:last-child {
  animation: float-up 1.5s cubic-bezier(0, 0.71, 0.29, 1) 0.5s 1 normal both;
}
#newsfeed .notification {
  display: block;
  padding: 0.75rem 2.25rem 0.75rem 1.125rem;
  margin-bottom: 1.125rem;
}
#newsfeed .notification > .delete {
  top: 0.75rem;
}
#newsfeed .notification .content {
  font-size: 0.75rem;
}
#newsfeed .news-title {
  font-weight: bold;
}
#newsfeed .news-date.is-recent-week {
  font-weight: bold;
}